<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="推送信息" prop="informationId" >
        <a-select v-model="form.informationId" placeholder="请选择" allow-clear>
          <a-select-option v-for="(item,key) in informationList" :key="key" :value="item.id">{{ item.title }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="推送设备" prop="deviceId" >
        <a-select v-model="form.deviceId" placeholder="请选择" allow-clear>
          <a-select-option v-for="(item,key) in devList" :key="key" :value="item.id">{{ item.code }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="推送时间" prop="pushTime" >
        <a-date-picker v-model="form.pushTime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"/>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { pageDevice } from '@/api/valve/device'
import { getPushRecord, addPushRecord, updatePushRecord, pageInformation } from '@/api/valve/messageAndPush'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        pushTime: '',
        deviceId: undefined,
        informationId: undefined,
        remark: '',
        status: 0
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      informationList: [],
      devList: [{
        code: '全部',
        id: '0'
      }],
      rules: {
        deviceId: [
          { required: true, message: '请选择推送设备', trigger: ['blur', 'change'] }
        ],
        informationId: [
          { required: true, message: '请选择推送信息', trigger: ['blur', 'change'] }
        ],
        pushTime: [
          { required: true, message: '请选择推送时间', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      pageInformation({ pageNum: 1, pageSize: 999999999, sortField: 'createTime', sortOrder: 'DESCEND' }).then(response => {
        this.informationList = response.data.records
      })
      pageDevice({ pageNum: 1, pageSize: 999999999, sortField: 'createTime', sortOrder: 'DESCEND' }).then(response => {
        const list = response.data.records
        list.map((item) => {
          this.devList.push(item)
        })
      })
      this.form = {
        id: null,
        pushTime: '',
        deviceId: undefined,
        informationId: undefined,
        remark: '',
        status: 0
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getPushRecord({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updatePushRecord(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addPushRecord(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
